import { useHistory, useLocation, useParams } from 'react-router-dom';
// import { useLocation, useHistory, useParams } from 'react-router';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
//  import { useLocation, useHistory, useParams } from 'react-router';
import {
    Avatar,
    Box,
    Button,
    Collapse,
    Dialog,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    ListItemButton,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
// import { Key, ContentCopy } from '@mui/icons-material';
import swal from 'sweetalert';
import { FormControlLabel, Snackbar, Switch } from '@material-ui/core';
import PasswordStrengthBar from 'react-password-strength-bar';
// validator
import copy from 'copy-to-clipboard';

// service


// util
// import { changepassword } from '../../../services/authService';
import { Modalcloseicon } from '../../assets/svgicons';
import { passwordGenerator } from '../../util/password-generator';
import FormPasswordField from '../../components/form/formpasswordfield';
import { ContentCopy, VpnKey } from '@mui/icons-material';
import { GeneralRequiredFieldValidator, PasswordValidator } from '../../components/validate/Validator';
import { errorSwal, successSwal } from '../../util/sweet-alert';
import API from '../../util/api';


const ChangePassword = ({ openchangepassword, setOpenChangepassword }) => {
    const history = useHistory();
    const [copysnack, setCopysnack] = useState(false);
    // Form Inputs
    const [changePasswordDetails, setChangePasswordDetails] = useState({
        oldPassword: '',
        newPassword: '',
        passwordConfirmation: '',
    });
    // Validation Errors
    const [validationErrors, setValidationErrors] = useState({
        oldPasswordError: '',
        newPasswordError: '',
        passwordConfirmationError: ''
    });

    // Password Strength
    const [passwordStrength, setPasswordStrength] = useState(0);

    // Generate and Copy Password
    const generatePassword = () => {


        const generatedPassword = passwordGenerator()

        setChangePasswordDetails({
            ...changePasswordDetails,
            ['newPassword']: generatedPassword,
            ['passwordConfirmation']: generatedPassword,
        });
    }
    const copyPassword = () => {
        setCopysnack(true)
        copy(changePasswordDetails.newPassword)
    }

    // Handle Inputs
    const handleTextInput = (event) => {
        setChangePasswordDetails({ ...changePasswordDetails, [event.target.name]: event.target.value });
    };



    const handleClose = () => {
        clearForm()
        setOpenChangepassword(false)
    }

    const clearForm = () => {
        setChangePasswordDetails({
            oldPassword: '',
            newPassword: '',
            passwordConfirmation: '',
        })
    }

    // token
    const token = useSelector((state) => state.auth.authToken)

    // Handle Submit
    const handleSubmit = async (e) => {
        e.preventDefault();

        setValidationErrors({
            ...validationErrors,
            ['oldPasswordError']: GeneralRequiredFieldValidator(changePasswordDetails.oldPassword),
            ['newPasswordError']: GeneralRequiredFieldValidator(changePasswordDetails.newPassword),
            ['passwordConfirmationError']: PasswordValidator(changePasswordDetails.newPassword, changePasswordDetails.passwordConfirmation)
        });

        if (
            GeneralRequiredFieldValidator(changePasswordDetails.oldPassword) == '' &&
            GeneralRequiredFieldValidator(changePasswordDetails.newPassword) == '' &&
            PasswordValidator(changePasswordDetails.newPassword, changePasswordDetails.passwordConfirmation) == ''
        ) {

            if ((changePasswordDetails.newPassword).localeCompare(changePasswordDetails.oldPassword) == 0) {
                setValidationErrors({
                    ...validationErrors,
                    ['newPasswordError']: 'New Password cannot be same as Old Password',
                });
                return false
            }

            const form = new FormData();

            form.append('old_password', changePasswordDetails.oldPassword);
            form.append('new_password', changePasswordDetails.newPassword);

            try {
                const sanctumResponse = await API.get('/sanctum/csrf-cookie')
                let headers = sanctumResponse.config.headers;
                headers.Authorization = `Bearer ${token}`
                const response = await API.post(`api/changepassword`, form, { headers: headers });
                if (response.status == 200) {
                    successSwal({ action: 'save', module: 'Password' })
                    handleClose()
                }
            } catch (error) {

                console.log('error', error.response.data)

                if (error.response.status == 400) {
                    if (error.response.data.hasOwnProperty('password'))
                        setValidationErrors({
                            ...validationErrors,
                            ['oldPasswordError']: error.response.data.password,
                        });
                } else {
                    errorSwal()
                    handleClose()
                }
            }
        }
    };


    return (
        <Dialog className={'addform-modal'} onClose={handleClose} open={openchangepassword}>
            <div className='addform-modal-title'>
                Change Password
                <Box className={'pull-right pointer'} component={'span'} onClick={handleClose}><Modalcloseicon height={'20px'} width={'20px'} /></Box>
            </div>
            <Box p={2}>
                <Grid container spacing={2}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <FormPasswordField
                            placeholderLabel="Old Password"
                            name="oldPassword"
                            value={changePasswordDetails.oldPassword}
                            onChangeFunction={handleTextInput}
                            errorMsg={validationErrors.oldPasswordError}
                        />
                        <FormPasswordField
                            placeholderLabel="New Password"
                            name="newPassword"
                            value={changePasswordDetails.newPassword}
                            onChangeFunction={handleTextInput}
                            errorMsg={validationErrors.newPasswordError}
                        />

                        <Box sx={{ mb: 2 }}>
                            <PasswordStrengthBar
                                password={changePasswordDetails.newPassword}
                                onChangeScore={(score, feedback) => setPasswordStrength(score)}
                            />
                        </Box>

                        <FormPasswordField
                            placeholderLabel="Confirm Password"
                            name="passwordConfirmation"
                            value={changePasswordDetails.passwordConfirmation}
                            onChangeFunction={handleTextInput}
                            errorMsg={validationErrors.passwordConfirmationError}
                        />
                    </Grid>

                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Button variant="outlined" size='small' className='mr-2' onClick={generatePassword} startIcon={<VpnKey />}>Generate Password</Button>
                        <Button variant="outlined" size='small' onClick={copyPassword} startIcon={<ContentCopy />}>Copy Password</Button>
                    </Grid>

                    <Grid item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: 'right' }}>
                        <Button className={'mr-2'} variant="outlined" onClick={handleClose} color="error" size="medium">
                            Cancel
                        </Button>
                        <Button variant="contained" onClick={handleSubmit} className={'btn-info'} type="submit" size="medium">
                            Save
                        </Button>
                    </Grid>
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right'
                        }}
                        open={copysnack}
                        autoHideDuration={2000}
                        onClose={() => setCopysnack(false)}
                        message="Code Copied"
                    />
                </Grid>
            </Box>
        </Dialog>
    )
}

export default ChangePassword;