const host = window.location.host;

let baseURL = ''
let env = ''
let hostURL = ''
if (host === 'localhost:3000') {
    baseURL = 'http://localhost:8000/'
    hostURL = 'http://localhost:3000/'
    env = 'local'
} else if (host === 'uat-emanage.xscad2.com') {
    baseURL = 'https://uat-emanage-api.xscad2.com/'
    hostURL = 'https://uat-emanage.xscad2.com/'
    env = 'uat'
} else if (host === 'emanage.xscad2.com') {
    baseURL = 'https://emanage-api.xscad2.com/'
    hostURL = 'https://emanage.xscad2.com/'
    env = 'production'
}

const appConfig = {
    baseURL: baseURL,
    hostURL: hostURL,
    env: env
}

export default appConfig